<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-desktop"></i> Devices</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row listing" v-if="!editor">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <input type="text" v-model="filter.search" class="form-control" placeholder="Keyword Search"/>
                                </div>
                                <div class="form-group mr-1">
                                    <select v-model="filter.status" class="form-control">
                                        <option value="" selected>-- Any Status --</option>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                    </select>
                                </div>
                                <button type="button" class="btn searchBtn text-white mr-1" style="background-color: #383838" @click="search"><i class="fa fa-search" title="Search"></i> <span>Search Devices</span></button>
                                <!--<button type="button" class="btn btn-success" @click="create"><i class="fa fa-plus" title="New"></i></button>-->
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div class="listing">
                        <table class="table table-hover bg-light text-dark">
                            <thead>
                            <tr>
                                <td>Browser</td>
                                <td>User Agent</td>
                                <td>Status</td>
                                <td>ID</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in items.data" v-bind:key="data._id">
                                <td>{{data.browser}}</td>
                                <td>{{data.useragent}}</td>
                                <td>{{data._id|makeID}}</td>
                                <td>
                                    <div class="btn-group icons">
                                        <button class="btn btn-info" @click="edit(data)" ><span class="fa fa-pencil" title="View"></span></button>
                                        <button class="btn btn-danger" @click="remove(data)"><span class="fa fa-trash" title="Delete"></span></button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="row">
                            <div class="col-12 col-sm-9">
                                <v-pagination
                                    v-model="page"
                                    :pageCount="Math.ceil(items.total/max)"
                                    :classes="bootstrapPaginationClasses"
                                    :labels="customLabels"
                                    @input="search"></v-pagination>
                            </div>
                            <div class="col-12 col-sm-3 text-right">
                                <span>{{items.data.length * Number(page|1)}}</span> of <span>{{items.total}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row editor" v-if="editor">
                <div class="col-12 col-sm-7">
                    <form class="form" role="form" style="" @submit="save">
                        <div class="row">
                            <div class="col-12 col-sm-5">
                                <div class="form-group">
                                    <label>Browser</label>
                                    <input type="text" v-model="item.browser" class="form-control required" required/>
                                </div>

                                <div class="form-group">
                                    <label>User Agent</label>
                                    <input type="text" v-model="item.useragent" class="form-control required" required/>
                                </div>

                                <div class="form-group">
                                    <label>Status</label>
                                    <select v-model="item.status" class="form-control required" required>
                                        <option value="">-- Status --</option>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group text-right">
                                    <button type="button" class="btn btn-secondary btn-sm mr-2" @click="cancel"><span class="glyphicon glyphicon-remove"></span> Cancel</button>
                                    <button type="submit" class="btn btn-success btn-lg"><span class="glyphicon glyphicon-ok"></span> Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import axios from 'axios'
    import swal from 'sweetalert'
    import vPagination from 'vue-plain-pagination'
    export default {
        name: 'Devices',
        props:['user'],
        components: {
            vPagination
        },
        data: function () {
            return {
                item: {},
                items: {total:0, data:[]},
                editor: false,
                filter: {name:'', status:''},
                max: 25,
                page: 1,
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                customLabels: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                },
                searching: false
            }
        },
        created: function(){
            window.addEventListener("keydown",this.windowListener);
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.editor && !this.searching){
                    this.search();
                }
            },
            create: function(){
                this.item = {};
                this.editor = true;
            },
            save: function(){
                //Handle saving the changes
                var request = {'apikey': this.$root.apikey, id:this.item._id, args:this.item};
                this.$root.preloader = true;

                axios({url:`${this.$root.serverUrl}/admin/devices`, data:request, responseType:'json', method:(this.item['_id'])? 'POST' : 'PUT'}).then(function(resp){
                        //Process the results
                        this.$root.preloader = false;
                        if(resp.data && !resp.data.error){
                            //Saved successfully, now update the parent
                            swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(function(val){
                                if(val) {
                                    this.editor = false;
                                    this.search();
                                }
                            }.bind(this));
                        } else {
                            //Handle errors
                            this.errormsg = (resp.data && resp.data.error)? resp.data.error : false;
                            this.$root.preloader = false;
                        }
                    }.bind(this)).catch(function(err){
                        this.$root.preloader = false;
                        this.errormsg = err.message||"An unexpected error occured";
                        swal({title:"Error", text: this.errormsg, icon:"error"});
                    }.bind(this))
            },
            remove: function(obj){
                //Handle removing the item
                swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then(function(val){
                    if(!val) return;

                    var request = {'apikey': this.$root.apikey, id:obj._id};
                    this.$root.preloader = true;

                    axios({url:`${this.$root.serverUrl}/admin/devices/${obj._id}`, data:request, responseType:'json', method:'DELETE'}).then(function(resp){
                            //Process the results
                            this.$root.preloader = false;
                            if(resp.data && !resp.data.error){
                                //Saved successfully, now update the parent
                                swal({title:"Remove", text:"Your request was completed successfully", icon:"success"}).then(function(){
                                    this.search();
                                }.bind(this));
                            } else {
                                //Handle errors
                                swal({title:"Remove", text:"Unable to complete your request", icon:"error"});
                            }
                        }.bind(this))
                        .catch(function(err){
                            this.$root.preloader = false;
                            this.errormsg = err.message||"An unexpected error occured";
                            swal({title:"Error", text: this.errormsg, icon:"error"});
                        }.bind(this))
                    }.bind(this));
            },
            edit: function(obj){
                //Edit the vendor item
                this.editor = true;
                this.item = Object.clone({}, obj);
            },
            cancel: function(){
                this.editor = false;
                this.errormsg = false;
            },
            search: function(){
                this.searching = true;
                //Load the items
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.filter.search) request.sSearch = this.filter.search;
                if(this.filter.type) request.filters.type = this.filter.type;
                if(this.filter.status) request.filters.status = this.filter.status;
                request.page = page
                request.max = this.max;
                this.$root.preloader = true;
                if(!Object.keys(request.filters))
                    delete request.filters;

                ///TODO fix search filters
                axios.get(`${this.$root.serverUrl}/admin/devices`, {params:request}).then(function(resp){
                    //Store the stats
                    this.items = (resp.data)? resp.data : {total:0, data:[]};
                    this.$root.preloader = false;
                    this.searching = false;
                }.bind(this));
            }
        }
    }
</script>

<style scoped>
.searchBtn span {
    max-width: 0;
    -webkit-transition: max-width 1s;
    -moz-transition: max-width 1s;
    -o-transition: max-width 1s;
    transition: max-width 1s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
}
button:hover span {
    max-width: 9rem;
}
</style>
